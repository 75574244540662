<template>
  <div>
    <h6
      v-if="data.length > 0"
      class="mt-2 mb-1"
    >
      {{ $t('Notas') }}
    </h6>
    <app-timeline
      v-if="data.length > 0"
      class="mb-2"
    >
      <app-timeline-item
        v-for="(row, index) in data"
        :key="`note-item-${index}`"
        variant="secondary"
      >
        <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
          <h6>{{ formateDate(row.sw103s04) }}</h6>
        </div>

        <div
          v-if="row.edition===false"
          class="box-txt"
        >
          <p>
            <!-- eslint-disable vue/no-v-html -->
            <span v-html="row.sw103s02" />
            <!--eslint-enable-->
          </p>

          <template
            v-if="disableActions!==true"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              size="sm"
              variant="outline-primary"
              @click.stop.prevent="editNote(row, true)"
            >
              {{ $t('Editar') }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              size="sm"
              variant="outline-danger"
              class="ml-1"
              @click.stop.prevent="deleteNote(row, index)"
            >
              {{ $t('Eliminar') }}
            </b-button>
          </template>
        </div>

        <div
          v-if="row.edition===true"
          class="box-edit"
        >
          <validation-provider
            #default="{ errors }"
            rules="required"
          >
            <div :class="errors.length > 0 ? 'is-invalid':null">
              <quill-editor
                v-model="row.sw103s02"
                :options="{
                  modules: {
                    toolbar: `#quill-toolbar-${row.sw103s01}`,
                  },
                  placeholder: '',
                  bounds: `#quill-toolbar-${row.sw103s01}`,
                }"
                class="quil-content border-bottom-0"
              />
              <div
                :id="`quill-toolbar-${row.sw103s01}`"
                class="quill-toolbar d-flex justify-content-end border-top-0 mb-1"
              >
                <button
                  v-b-tooltip.hover.top="$t('Negrito')"
                  class="ql-bold"
                />
                <button
                  v-b-tooltip.hover.top="$t('Itálico')"
                  class="ql-italic"
                />
                <button
                  v-b-tooltip.hover.top="$t('Sublinhado')"
                  class="ql-underline"
                />
                <button
                  v-b-tooltip.hover.top="$t('Lista')"
                  class="ql-list"
                  value="ordered"
                />
                <button
                  v-b-tooltip.hover.top="$t('Lista numerada')"
                  class="ql-list"
                  value="bullet"
                />
                <button
                  v-b-tooltip.hover.top="$t('Remover indentação')"
                  class="ql-indent"
                  value="-1"
                />
                <button
                  v-b-tooltip.hover.top="$t('Indentar')"
                  class="ql-indent"
                  value="+1"
                />
                <button
                  v-b-tooltip.hover.top="$t('Inserir link')"
                  class="ql-link"
                />
                <button
                  v-b-tooltip.hover.top="$t('Remover formatação')"
                  class="ql-clean"
                />
              </div>
            </div>
          </validation-provider>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            variant="outline-primary"
            @click.stop.prevent="saveEditNote(row, index)"
          >
            {{ $t('Guardar') }}
          </b-button>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            size="sm"
            variant="outline-secondary"
            class="ml-1"
            @click.stop.prevent="editNote(row, false)"
          >
            {{ $t('Cancelar') }}
          </b-button>
        </div>

      </app-timeline-item>
    </app-timeline>
  </div>
</template>

<script>
import {
  BButton, VBTooltip,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { format, parseISO } from 'date-fns'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from '@/views/modules/components/editor-html'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BButton,
    quillEditor,
    ValidationProvider,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    deleteNote: {
      type: Function,
      required: true,
    },
    editNote: {
      type: Function,
      required: true,
    },
    saveEditNote: {
      type: Function,
      required: true,
    },
    disableActions: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
    }
  },
  methods: {
    formateDate(date) {
      return `${format(parseISO(date), 'yyyy-MM-dd')} ${format(parseISO(date), 'HH')}h${format(parseISO(date), 'mm')}`
    },
  },
}
</script>

<style lang="scss" scoped>
.quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + .quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

::v-deep .ql-snow .ql-tooltip {
  white-space:normal!important;
}
</style>
