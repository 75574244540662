<template>
  <div>
    <b-sidebar
      sidebar-class="sidebar-lg"
      visible
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="closeModalForm"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ titleModal }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>

        <b-overlay
          :show="processingAjax"
          rounded="sm"
        >
          <vue-perfect-scrollbar
            class="b-sidebar-scrollarea-form"
            :settings="configSettingsScroll"
          >
            <b-container
              class="pt-2 pb-0 pl-2 pr-2"
              fluid
            >

              <!-- Botoes de acoes -->
              <b-row
                v-if="checkShowButtonsMain===true"
                v-show="showFormEdition===false"
              >
                <b-col md="12">
                  <b-button-group class="w-100">
                    <!-- Efetuado -->
                    <b-button
                      v-if="checkExistAction('checkStateRotinaReg')!==null"
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      :variant="((checkExistAction('checkStateRotinaReg').active === true) ? 'success' : 'outline-success')"
                      class="w-50"
                      :disabled="checkExistAction('checkStateRotinaReg').active || checkExistAction('uncheckStateRotinaReg').active"
                      @click.stop.prevent="markStatusReg('checkStateRotinaReg', 'rotina', true, false)"
                    >
                      {{ checkExistAction('checkStateRotinaReg').txt }}
                    </b-button>

                    <b-button
                      v-if="checkExistAction('checkStateCampanhaReg')!==null"
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      :variant="((checkExistAction('checkStateCampanhaReg').active === true) ? 'success' : 'outline-success')"
                      class="w-50"
                      :disabled="checkExistAction('checkStateCampanhaReg').active || checkExistAction('uncheckStateCampanhaReg').active"
                      @click.stop.prevent="markStatusReg('checkStateCampanhaReg', 'campanha', true, false)"
                    >
                      {{ checkExistAction('checkStateCampanhaReg').txt }}
                    </b-button>

                    <b-button
                      v-if="checkExistAction('checkStateReg')!==null"
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      :variant="((checkExistAction('checkStateReg').active === true) ? 'success' : 'outline-success')"
                      class="w-50"
                      :disabled="checkExistAction('checkStateReg').active || checkExistAction('uncheckStateReg').active"
                      @click.stop.prevent="markStatusReg('checkStateReg', null, true, true)"
                    >
                      {{ checkExistAction('checkStateReg').txt }}
                    </b-button>

                    <!-- Nao Efetuado -->
                    <b-button
                      v-if="checkExistAction('uncheckStateRotinaReg')!==null"
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      :variant="((checkExistAction('uncheckStateRotinaReg').active === true) ? 'danger' : 'outline-danger')"
                      class="w-50"
                      :disabled="checkExistAction('uncheckStateRotinaReg').active || checkExistAction('checkStateRotinaReg').active"
                      @click.stop.prevent="markStatusReg('uncheckStateRotinaReg', 'rotina', false, false)"
                    >
                      {{ checkExistAction('uncheckStateRotinaReg').txt }}
                    </b-button>

                    <b-button
                      v-if="checkExistAction('uncheckStateCampanhaReg')!==null"
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      :variant="((checkExistAction('uncheckStateCampanhaReg').active === true) ? 'danger' : 'outline-danger')"
                      class="w-50"
                      :disabled="checkExistAction('uncheckStateCampanhaReg').active || checkExistAction('checkStateCampanhaReg').active"
                      @click.stop.prevent="markStatusReg('uncheckStateCampanhaReg', 'campanha', false, false)"
                    >
                      {{ checkExistAction('uncheckStateCampanhaReg').txt }}
                    </b-button>

                    <b-button
                      v-if="checkExistAction('uncheckStateReg')!==null"
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      :variant="((checkExistAction('uncheckStateReg').active === true) ? 'danger' : 'outline-danger')"
                      class="w-50"
                      :disabled="checkExistAction('uncheckStateReg').active || checkExistAction('checkStateReg').active"
                      @click.stop.prevent="markStatusReg('uncheckStateReg', null, false, false)"
                    >
                      {{ checkExistAction('uncheckStateReg').txt }}
                    </b-button>

                  </b-button-group>
                </b-col>
              </b-row>

              <b-row
                v-if="checkExistMoreAction===true"
                v-show="showFormEdition===false"
              >
                <b-col md="12">
                  <app-collapse
                    type="margin"
                    class="mb-1"
                  >
                    <app-collapse-item
                      ref="blockMoreActions"
                      :title="$t('Mais ações')"
                    >

                      <b-button
                        v-if="checkExistAction('editseguimentoUserResp')!==null"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        block
                        @click.stop.prevent="editRespReg"
                      >
                        {{ checkExistAction('editseguimentoUserResp').txt }}
                      </b-button>

                      <b-button
                        v-if="checkExistAction('editseguimentodata')!==null"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        block
                        @click.stop.prevent="editDateReg"
                      >
                        {{ checkExistAction('editseguimentodata').txt }}
                      </b-button>

                      <b-button
                        v-if="checkExistAction('editseguimento')!==null"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        block
                        @click.stop.prevent="editReg"
                      >
                        {{ checkExistAction('editseguimento').txt }}
                      </b-button>

                      <b-button
                        v-if="checkExistAction('changeStatusDatesRotina')!==null"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        block
                        @click.stop.prevent="changeStatusDatesRoutineReg"
                      >
                        {{ checkExistAction('changeStatusDatesRotina').txt }}
                      </b-button>

                      <b-button
                        v-if="checkExistAction('concluirRotina')!==null"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        block
                        @click.stop.prevent="finishRoutineReg"
                      >
                        {{ checkExistAction('concluirRotina').txt }}
                      </b-button>

                      <b-button
                        v-if="checkExistAction('deleteRotinaReg')!==null"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        block
                        @click.stop.prevent="deleteRoutineReg"
                      >
                        {{ checkExistAction('deleteRotinaReg').txt }}
                      </b-button>

                      <b-button
                        v-if="checkExistAction('deleteReg')!==null"
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        block
                        @click.stop.prevent="deleteReg"
                      >
                        {{ checkExistAction('deleteReg').txt }}
                      </b-button>

                    </app-collapse-item>
                  </app-collapse>

                </b-col>
              </b-row>

              <!-- Detalhe -->
              <b-row>
                <b-col md="12">
                  <list-data-item
                    :row="row"
                    :with-name-lead="withNameLead"
                    :show-button-view="false"
                    :mode-view="true"
                  />
                </b-col>
              </b-row>

              <!-- Gestao Notas -->
              <b-row
                v-show="showFormEdition===false && showNotes===true"
              >
                <b-col md="12">

                  <!-- Notas :: Formulario -->
                  <template
                    v-if="checkIsDelete===false"
                  >
                    <b-row>
                      <b-col md="12">
                        <validation-observer
                          #default="{ }"
                          ref="formSaveValidatorSeguimento"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                          >
                            <b-form-group
                              :label="$t('Nova nota')"
                              :class="errors.length > 0 ? 'is-invalid':null"
                            >
                              <b-overlay
                                :show="savingNote"
                                rounded="sm"
                              >
                                <quill-editor
                                  v-model="note"
                                  :options="{
                                    modules: {
                                      toolbar: '#quill-toolbar-new',
                                    },
                                    placeholder: '',
                                    bounds: '#quill-toolbar-new',
                                  }"
                                  class="quil-content border-bottom-0"
                                />
                                <div
                                  id="quill-toolbar-new"
                                  class="quill-toolbar d-flex justify-content-end border-top-0"
                                >
                                  <button
                                    v-b-tooltip.hover.top="$t('Negrito')"
                                    class="ql-bold"
                                  />
                                  <button
                                    v-b-tooltip.hover.top="$t('Itálico')"
                                    class="ql-italic"
                                  />
                                  <button
                                    v-b-tooltip.hover.top="$t('Sublinhado')"
                                    class="ql-underline"
                                  />
                                  <button
                                    v-b-tooltip.hover.top="$t('Lista')"
                                    class="ql-list"
                                    value="ordered"
                                  />
                                  <button
                                    v-b-tooltip.hover.top="$t('Lista numerada')"
                                    class="ql-list"
                                    value="bullet"
                                  />
                                  <button
                                    v-b-tooltip.hover.top="$t('Remover indentação')"
                                    class="ql-indent"
                                    value="-1"
                                  />
                                  <button
                                    v-b-tooltip.hover.top="$t('Indentar')"
                                    class="ql-indent"
                                    value="+1"
                                  />
                                  <button
                                    v-b-tooltip.hover.top="$t('Inserir link')"
                                    class="ql-link"
                                  />
                                  <button
                                    v-b-tooltip.hover.top="$t('Remover formatação')"
                                    class="ql-clean"
                                  />
                                </div>
                                <template #overlay>
                                  <div class="text-center">
                                    <p id="cancel-label">
                                      {{ $t('Aguarde, a guardar...') }}
                                    </p>
                                  </div>
                                </template>
                              </b-overlay>
                            </b-form-group>
                          </validation-provider>
                        </validation-observer>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          size="sm"
                          variant="primary"
                          :disabled="savingNote"
                          @click="saveNewNote"
                        >
                          {{ $t('Criar nota') }}
                        </b-button>
                      </b-col>
                    </b-row>
                  </template>

                  <!-- Notas :: Listagem -->
                  <b-row>
                    <b-col md="12">
                      <validation-observer
                        #default="{ }"
                        ref="formSaveValidatorSeguimentoEdit"
                      >
                        <list-notes
                          :data="notes"
                          :delete-note="deleteNote"
                          :edit-note="editNote"
                          :save-edit-note="saveEditNote"
                          :disable-actions="checkIsDelete"
                        />
                      </validation-observer>
                    </b-col>
                  </b-row>

                </b-col>
              </b-row>

              <!-- Editar detalhe do seguimento -->
              <b-row
                v-if="showFormEdition===true"
              >
                <b-col md="12">
                  <validation-observer
                    #default="{ }"
                    ref="formSaveValidatorEdition"
                  >
                    <component
                      :is="componentDataEdition"
                      ref="formEditionData"
                      :row="row"
                    />
                  </validation-observer>
                </b-col>
              </b-row>

            </b-container>
          </vue-perfect-scrollbar>

          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">
                {{ $t('Aguarde, a processar...') }}
              </p>
            </div>
          </template>

        </b-overlay>

        <div
          v-if="showFormEdition===false"
          class="d-flex p-1 justify-content-center"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click.stop.prevent="hide"
          >
            {{ $t('Fechar') }}
          </b-button>
        </div>

        <div
          v-if="showFormEdition===true"
          class="d-flex p-1"
          :class="{
            'justify-content-start': (showFormEditionHiddenButtonClear===true),
            'justify-content-center': (showFormEditionHiddenButtonClear===false)
          }"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            :disabled="processingAjax"
            @click.stop.prevent="saveFormEdition"
          >
            {{ $t('Guardar') }}
          </b-button>
          <b-button
            v-if="showFormEditionHiddenButtonClear===false"
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mr-2"
            :disabled="processingAjax"
            @click.stop.prevent="clearFormEdition"
          >
            {{ $t('Limpar') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            :disabled="processingAjax"
            @click.stop.prevent="cancelFormEdition"
          >
            {{ $t('Cancelar') }}
          </b-button>
        </div>

      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BFormGroup, BButton, BRow, BCol, BContainer, BButtonGroup, BOverlay, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import checkNested from 'check-nested'
import { showModalDeleteMessage } from '@core/utils/utils'
import btoa from 'btoa'
import { quillEditor } from '@/views/modules/components/editor-html'
import { ref } from '@vue/composition-api'
import { mapGetters } from 'vuex'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../../mixins'
import ListDataItem from './listDataItem.vue'
import ListNotes from './listNotes.vue'

export default {
  components: {
    BButton,
    BSidebar,
    BFormGroup,
    BRow,
    BCol,
    ValidationObserver,
    ValidationProvider,
    VuePerfectScrollbar,
    BContainer,
    BButtonGroup,
    AppCollapse,
    AppCollapseItem,
    ListDataItem,
    ListNotes,
    BOverlay,
    quillEditor,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  props: {
    rowData: {
      type: Object,
      default: () => {},
      required: false,
    },
    withNameLead: {
      type: Boolean,
      default: false,
      required: false,
    },
    createNew: {
      type: Function,
      default: null,
      required: false,
    },
    refreshListview: {
      type: Function,
      required: false,
      default: () => {},
    },
    closeModalViewSeguimento: {
      type: Function,
      required: false,
      default: () => null,
    },
    showNotes: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data() {
    return {
      note: null,
      notes: [],
      savingNote: false,
      processingAjax: false,
      activeRefreshListview: false,

      showFormEdition: false,
      showFormEditNoteSeg: false,
      showFormEditDateSeg: false,
      showFormEditRespSeg: false,
      formEditionType: null,
      componentDataEdition: null,
      showFormEditionHiddenButtonClear: false,

      titleModal: this.$t('Detalhe do seguimento'),
    }
  },
  computed: {
    ...mapGetters('seguimentos', ['infoSeguimento']),
    checkExistMoreAction() {
      if (checkNested(this.row.extra, 'actionsSeg')) {
        const oAction = this.row.extra.actionsSeg.filter(o => o.main === false)
        return ((oAction.length > 0))
      }
      return false
    },
    checkShowButtonsMain() {
      if (checkNested(this.row.extra, 'actionsSeg')) {
        const oAction = this.row.extra.actionsSeg.filter(o => o.main === true)
        return ((oAction.length > 0))
      }
      return false
    },
    checkIsDelete() {
      return Number(this.row.sw030s10) === 1
    },
  },
  mounted() {
    this.getNotesSeguimento()
  },
  methods: {
    closeModalForm() {
      if (typeof this.$parent.closeView !== 'undefined') {
        this.$parent.closeView()
      }

      if (typeof this.closeModalViewSeguimento !== 'undefined' && this.closeModalViewSeguimento !== null) {
        this.closeModalViewSeguimento()
      }

      if (this.activeRefreshListview === true) {
        this.refreshListview()
      }
    },
    async saveNewNote() {
      const self = this
      if ((typeof self.clicked === 'undefined') || (self.clicked === false)) {
        self.clicked = true
        await this.$refs.formSaveValidatorSeguimento.validate()
          .then(async valid => {
            if (valid) {
              self.savingNote = true
              this.$store.dispatch('seguimentos/addNoteToSeguimento', { sw103s02: this.note, sw103s03: btoa(this.row.sw030s01) }).then(() => {
                self.savingNote = false
                self.note = null
                self.$refs.formSaveValidatorSeguimento.reset()
                self.getNotesSeguimento()
              }).catch(error => {
                self.savingNote = false
                self.showMsgErrorRequest(error)
              })
            } else {
              this.showMsgErrorRequest(new Error(this.$t('Tem de preencher a nota')))
            }
          })
          .catch(() => {
            this.showMsgErrorRequest(new Error(this.$t('Tem de preencher a nota')))
          })

        setTimeout(() => {
          self.clicked = false
        }, 1000)
      }
    },
    checkExistAction(action) {
      if (checkNested(this.row.extra, 'actionsSeg')) {
        const oAction = this.row.extra.actionsSeg.filter(o => o.action === action)

        if (oAction.length > 0) {
          return oAction.shift()
        }
      }

      return null
    },
    getNotesSeguimento() {
      this.$store.dispatch('seguimentos/getNotesBySeguimentos', { sw103s03: btoa(this.row.sw030s01) }).then(res => {
        this.notes = res || []
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async deleteNote(row, index) {
      if (checkNested(row, 'sw103s01') && (row.sw103s01 !== '')) {
        try {
          const actionModal = new Promise(resolve => {
            showModalDeleteMessage(resolve, `${this.$t('Pretende eliminar a nota?')}<br>${this.$t('Esta ação é irreversível!')}`)
          })

          await actionModal.then(result => {
            if (result === 'confirm') {
              this.processingAjax = true
              this.$store.dispatch('seguimentos/deleteNoteSeguimento', {
                sw103s01: btoa(row.sw103s01),
              }).then(async res => {
                this.processingAjax = false
                this.notes.splice(index, 1)
                this.showMsgSuccessRequest(res)
              }).catch(error => {
                this.processingAjax = false
                this.showMsgErrorRequest(error)
              })
            }
          })
        } catch (err) {
          //
        }
      }
    },
    editNote(row, status) {
      if (checkNested(row, 'sw103s01') && (row.sw103s01 !== '')) {
        try {
          const oRowUpdate = row

          if (status === true) {
            oRowUpdate.edition = true
            oRowUpdate.sw103s02_bck = row.sw103s02 || ''
          } else {
            oRowUpdate.edition = false
            oRowUpdate.sw103s02 = row.sw103s02_bck || ''
          }
        } catch (err) {
          //
        }
      }
    },
    async saveEditNote(row) {
      if (checkNested(row, 'sw103s01') && (row.sw103s01 !== '')) {
        const self = this
        if ((typeof self.clicked === 'undefined') || (self.clicked === false)) {
          self.clicked = true
          await this.$refs.formSaveValidatorSeguimentoEdit.validate()
            .then(async valid => {
              if (valid) {
                this.processingAjax = true
                this.$store.dispatch('seguimentos/updateNoteSeguimento', {
                  sw103s01: btoa(row.sw103s01),
                  sw103s02: row.sw103s02,
                }).then(async res => {
                  this.processingAjax = false

                  const oRowUpdate = row
                  oRowUpdate.sw103s02_bck = oRowUpdate.sw103s02
                  oRowUpdate.edition = false

                  this.showMsgSuccessRequest(res)
                }).catch(error => {
                  this.processingAjax = false
                  this.showMsgErrorRequest(error)
                })
              } else {
                this.showMsgErrorRequest(new Error(this.$t('Tem de preencher a nota')))
              }
            })
            .catch(() => {
              this.showMsgErrorRequest(new Error(this.$t('Tem de preencher a nota')))
            })

          setTimeout(() => {
            self.clicked = false
          }, 1000)
        }
      }
    },
    async markStatusReg(tagAction = null, action = null, status = false, createNew = false) {
      if (this.row.sw030s01 !== '') {
        let sMessage = ''
        let sIconMessage = ''
        if (status === true) {
          sMessage = `${this.$t('Marcar o seguimento como {estado}?', { estado: `<b class="text-success">${this.$t('Efetuado')}</b>` })}`
          sIconMessage = 'question'
        } else {
          sMessage = `${this.$t('Marcar o seguimento como {estado}?', { estado: `<b class="text-danger">${this.$t('Não Efetuado')}</b>` })}`
          sIconMessage = 'question'
        }

        await this.$swal({
          title: '',
          html: sMessage,
          icon: sIconMessage,
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('Sim'),
          cancelButtonText: this.$t('Não'),
          customClass: {
            confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
            cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            this.$root.$emit('app::loading', true)
            await this.$store.dispatch('seguimentos/changeStateSeguimento', {
              sw030s01: btoa(this.row.sw030s01),
              sw030s03: this.row.sw030s03 || '',
              sw030s08: this.row.sw030s08 || '',
              type: ((status === true) ? 1 : 2),
              note: '',
              action,
            }).then(async res => {
              this.$root.$emit('app::loading', false)
              await this.showMsgSuccessRequest(res)

              this.row.extra.actionsSeg.filter(o => o.action === tagAction)[0].active = true
              this.row = { ...this.row }

              this.activeRefreshListview = true

              if (createNew === true) {
                if (this.createNew !== null) {
                  await this.modalQuestionCreateNewSeguimento()
                }
              }
            }).catch(error => {
              this.$root.$emit('app::loading', false)
              this.showMsgErrorRequest(error)
            })
          }
        })
      }
    },
    async modalQuestionCreateNewSeguimento() {
      await this.$swal({
        title: '',
        html: `${this.$t('Deseja criar um novo seguimento?')}`,
        icon: 'question',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: this.$t('Sim'),
        cancelButtonText: this.$t('Não'),
        customClass: {
          confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
          cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.closeModalForm()
          if (this.createNew !== null) {
            this.createNew()
          }
        }
      })
    },
    finishRoutineReg() {
      if (this.row.sw030s01 !== '') {
        this.$swal({
          title: '',
          html: `${this.$t('Marcar a rotina como {estado}?', { estado: `<b>${this.$t('concluída')}</b>` })}`,
          icon: 'question',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('Sim'),
          cancelButtonText: this.$t('Não'),
          customClass: {
            confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
            cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            this.$root.$emit('app::loading', true)
            this.$store.dispatch('seguimentos/changeStateSeguimentoRotina', {
              sw030s01: btoa(this.row.sw030s01),
              sw030s03: this.row.sw030s03 || '',
              sw030s08: this.row.sw030s08 || '',
              type: 4,
              note: '',
            }).then(async res => {
              this.$root.$emit('app::loading', false)
              this.showMsgSuccessRequest(res)

              this.row.extra.actionsSeg.filter(o => o.action === 'checkStateRotinaReg')[0].active = true
              this.row.extra.actionsSeg.splice(this.row.extra.actionsSeg.findIndex(o => o.action === 'concluirRotina'), 1)
              this.row = { ...this.row }

              this.activeRefreshListview = true
            }).catch(error => {
              this.$root.$emit('app::loading', false)
              this.showMsgErrorRequest(error)
            })
          }
        })
      }
    },
    async deleteRoutineReg() {
      if (this.row.sw030s01 !== '') {
        const actionModal = new Promise(resolve => {
          showModalDeleteMessage(resolve, `${this.$t('Tem a certeza que pretende eliminar a rotina?')}<br>${this.$t('Esta ação é irreversível!')}`)
        })

        await actionModal.then(result => {
          if (result === 'confirm') {
            const actionModalConfirm = new Promise(resolve => {
              showModalDeleteMessage(resolve, `${this.$t('O registo vai ser eliminado definitivamente.')}<br>${this.$t('Tem a certeza que pretende continuar?')}`)
            })

            actionModalConfirm.then(resultConfirm => {
              if (resultConfirm === 'confirm') {
                this.$root.$emit('app::loading', true)
                this.$store.dispatch('seguimentos/removeSeguimentoRotina', {
                  sw030s01: btoa(this.row.sw030s01),
                  sw030s03: this.row.sw030s03 || '',
                  sw030s08: this.row.sw030s08 || '',
                  type: 3,
                  note: '',
                }).then(async res => {
                  this.$root.$emit('app::loading', false)
                  this.showMsgSuccessRequest(res)
                  this.activeRefreshListview = true
                  this.closeModalForm()
                }).catch(error => {
                  this.$root.$emit('app::loading', false)
                  this.showMsgErrorRequest(error)
                })
              }
            })
          }
        })
      }
    },
    deleteReg() {
      if (this.row.sw030s01 !== '') {
        // Verifica se o seguimento está como "Nao efetuado"
        let showButtonUncheckStateReg = true
        let sMessage = `${this.$t('Tem a certeza que pretende eliminar o seguimento?')}`
        if ((this.checkExistAction('uncheckStateReg') !== null) && (this.checkExistAction('uncheckStateReg').active === true)) {
          showButtonUncheckStateReg = false
        } else {
          sMessage += `<br>${this.$t('Pode marcar como {estado} no lugar de o apagar definitivamente.', { estado: `<b>${this.$t('Não efectuado')}</b>` })}`
        }

        this.$swal({
          title: '',
          html: sMessage,
          icon: 'question',
          showCancelButton: true,
          reverseButtons: true,
          showDenyButton: showButtonUncheckStateReg,
          confirmButtonText: this.$t('Eliminar'),
          denyButtonText: this.$t('Não efetuado'),
          cancelButtonText: this.$t('Cancelar'),
          customClass: {
            confirmButton: 'btn btn-danger ml-sm-1 btn-xs-block',
            denyButton: 'btn btn-outline-danger mb-1 mb-sm-0 ml-sm-1 btn-xs-block',
            cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value === true) {
            this.$swal({
              title: '',
              html: `${this.$t('O registo vai ser eliminado definitivamente.')}<br>${this.$t('Tem a certeza que pretende continuar?')}`,
              icon: 'question',
              showCancelButton: true,
              reverseButtons: true,
              confirmButtonText: this.$t('Eliminar definitivamente'),
              cancelButtonText: this.$t('Cancelar'),
              customClass: {
                confirmButton: 'btn btn-danger ml-sm-1 btn-xs-block',
                cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
              },
              buttonsStyling: false,
            }).then(async resultConfirm => {
              if (resultConfirm.value) {
                this.$root.$emit('app::loading', true)
                this.$store.dispatch('seguimentos/removeSeguimento', {
                  sw030s01: btoa(this.row.sw030s01),
                  sw030s03: this.row.sw030s03 || '',
                  sw030s08: this.row.sw030s08 || '',
                  type: 3,
                  note: '',
                }).then(async res => {
                  this.$root.$emit('app::loading', false)
                  this.showMsgSuccessRequest(res)
                  this.activeRefreshListview = true
                  this.closeModalForm()
                }).catch(error => {
                  this.$root.$emit('app::loading', false)
                  this.showMsgErrorRequest(error)
                })
              }
            })
          } else if (result.value === false) {
            this.markStatusReg('uncheckStateReg', null, false, false)
          }
        })
      }
    },
    showFormEditionType(type) {
      this.formEditionType = type || null
      this.showFormEditionHiddenButtonClear = false

      if (type === 'note') {
        this.showFormEdition = true
        this.componentDataEdition = () => import(/* webpackChunkName: "crm-leads-seguimento-form-edit-note" */ /* webpackPrefetch: true */ './formEditNote.vue')
        this.titleModal = this.$t('Editar seguimento')
      } else if (type === 'date') {
        this.showFormEdition = true
        this.componentDataEdition = () => import(/* webpackChunkName: "crm-leads-seguimento-form-edit-date" */ /* webpackPrefetch: true */ './formEditDate.vue')
        this.titleModal = this.$t('Alterar data')
      } else if (type === 'resp') {
        this.showFormEdition = true
        this.componentDataEdition = () => import(/* webpackChunkName: "crm-leads-seguimento-form-edit-responsible" */ /* webpackPrefetch: true */ './formEditResp.vue')
        this.titleModal = this.$t('Alterar responsável')
      } else if (type === 'changeStatus') {
        this.showFormEdition = true
        this.componentDataEdition = () => import(/* webpackChunkName: "crm-leads-seguimento-form-edit-change-status-date" */ /* webpackPrefetch: true */ './formEditStatusDate.vue')
        this.titleModal = this.$t('Alterar estado dos seguimentos')
      } else {
        this.showFormEdition = false
        this.componentDataEdition = null
        this.titleModal = this.$t('Detalhe do seguimento')

        if (typeof this.closeModalViewSeguimento !== 'undefined' && this.closeModalViewSeguimento !== null) {
          this.closeModalViewSeguimento()
        }
      }
    },
    editReg() {
      this.$refs.blockMoreActions.updateVisible(false)
      this.showFormEditionType('note')
    },
    async editRespReg() {
      await this.$store.dispatch('seguimentos/popupEditSeguimentoUserResp', {
        sw030s01: btoa(this.row.sw030s01),
      }).then(() => {
        this.$refs.blockMoreActions.updateVisible(false)
        this.showFormEditionType('resp')
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async editDateReg() {
      await this.$store.dispatch('seguimentos/popupAddSeguimento', {
        keyReg: this.keyReg || '',
        typeLead: this.typeLead || '',
      }).then(async () => {
        this.$refs.blockMoreActions.updateVisible(false)
        this.showFormEditionType('date')
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async changeStatusDatesRoutineReg() {
      await this.$store.dispatch('seguimentos/popupEditRotinaUpdates', {
        sw030s01: btoa(this.row.sw030s01),
      }).then(() => {
        if (this.infoSeguimento.datasRoutina.length > 0) {
          this.$refs.blockMoreActions.updateVisible(false)
          this.showFormEditionType('changeStatus')
        } else {
          this.showMsgErrorRequest({ message: this.$t('Não existem datas para atualizar o  estado') })
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    clearFormEdition() {
      if (typeof this.$refs.formEditionData.clear !== 'undefined') {
        this.$refs.formEditionData.clear()
      }
    },
    cancelFormEdition() {
      this.showFormEditionType('')
    },
    async saveFormEdition() {
      if (this.formEditionType === 'note') {
        await this.saveFormEditionNote()
      }
      if (this.formEditionType === 'date') {
        await this.saveFormEditionDate()
      }
      if (this.formEditionType === 'resp') {
        await this.saveFormEditionResp()
      }
      if (this.formEditionType === 'changeStatus') {
        await this.saveFormEditionChangeStatus()
      }
    },
    async saveFormEditionNote() {
      const self = this
      if ((typeof self.clicked === 'undefined') || (self.clicked === false)) {
        self.clicked = true
        await this.$refs.formSaveValidatorEdition.validate()
          .then(async valid => {
            if (valid) {
              this.processingAjax = true
              this.$store.dispatch('seguimentos/updateSeguimento', {
                sw030s01: btoa(this.row.sw030s01),
                sw030s05: this.$refs.formEditionData.getData().sw030s05 || '',
              }).then(async res => {
                this.processingAjax = false

                this.row = { ...this.row, ...this.$refs.formEditionData.getData() }

                this.activeRefreshListview = true

                this.showMsgSuccessRequest(res)
                this.cancelFormEdition()
              }).catch(error => {
                this.processingAjax = false
                this.showMsgErrorRequest(error)
              })
            } else {
              this.showMsgErrorRequest(new Error(this.$t('Preencha o seguimento')))
            }
          })
          .catch(() => {
            this.showMsgErrorRequest(new Error(this.$t('Preencha o seguimento')))
          })

        setTimeout(() => {
          self.clicked = false
        }, 1000)
      }
    },
    async saveFormEditionDate() {
      const self = this
      if ((typeof self.clicked === 'undefined') || (self.clicked === false)) {
        self.clicked = true
        await this.$refs.formSaveValidatorEdition.validate()
          .then(async valid => {
            if (valid) {
              this.processingAjax = true

              // Se for rotina e estao estiver tratada, obtem as opções de alteração
              if ((Number(this.row.sw030s14) === 1) && (Number(this.row.sw030s23) !== 1)) {
                await this.$store.dispatch('seguimentos/modalChangeSeguimento', {
                  swid: btoa(this.row.sw030s01),
                  swDateNew: `${this.$refs.formEditionData.getData().sw030s04} ${this.$refs.formEditionData.getData().sw030s04_time}`,
                  swDateOld: this.row.sw030s04,
                  swtype: this.row.sw030s14,
                  swAllDay: ((this.$refs.formEditionData.getData().sw030s18 === 1)),
                }).then(async data => {
                  const actionModalConfirmChangeDate = new Promise(resolve => {
                    this.$refs.formEditionData.openModalConfirmChangeDate(data, resolve)
                  })

                  await actionModalConfirmChangeDate.then(async resultConfirm => {
                    this.$refs.formEditionData.closeModalConfirmChangeDate()
                    if (resultConfirm.confirmAction === false) {
                      this.processingAjax = false
                    } else {
                      await this.$store.dispatch('seguimentos/updateEvent', {
                        swid: btoa(this.row.sw030s01),
                        swdate: `${this.$refs.formEditionData.getData().sw030s04} ${this.$refs.formEditionData.getData().sw030s04_time}`,
                        swOldDate: this.row.sw030s04,
                        swtype: this.row.sw030s14,
                        swchk: resultConfirm.confirmAction,
                      }).then(async res => {
                        this.processingAjax = false

                        this.row = {
                          ...this.row,
                          ...{
                            sw030s04: `${this.$refs.formEditionData.getData().sw030s04} ${this.$refs.formEditionData.getData().sw030s04_time}`,
                            sw030s18: this.$refs.formEditionData.getData().sw030s18,
                          },
                        }

                        await this.$store.dispatch('seguimentos/getInfoExtraDataRow', this.row).then(extraUpdate => {
                          this.row.extra = extraUpdate
                        })

                        this.activeRefreshListview = true

                        this.showMsgSuccessRequest(res)
                        this.cancelFormEdition()
                      }).catch(error => {
                        this.processingAjax = false
                        this.showMsgErrorRequest(error)
                      })
                    }
                  })
                }).catch(error => {
                  this.processingAjax = false
                  this.showMsgErrorRequest(error)
                })
              } else {
                await this.$store.dispatch('seguimentos/updateSeguimentoData', {
                  sw030s01: btoa(this.row.sw030s01),
                  sw030s04: this.$refs.formEditionData.getData().sw030s04 || '',
                  sw030s04_time: this.$refs.formEditionData.getData().sw030s04_time || '',
                  sw030s18: this.$refs.formEditionData.getData().sw030s18 || '',
                }).then(async res => {
                  this.processingAjax = false

                  this.row = {
                    ...this.row,
                    ...{
                      sw030s04: `${this.$refs.formEditionData.getData().sw030s04} ${this.$refs.formEditionData.getData().sw030s04_time}`,
                      sw030s18: this.$refs.formEditionData.getData().sw030s18,
                    },
                  }

                  await this.$store.dispatch('seguimentos/getInfoExtraDataRow', this.row).then(extraUpdate => {
                    this.row.extra = extraUpdate
                  })

                  this.activeRefreshListview = true

                  this.showMsgSuccessRequest(res)
                  this.cancelFormEdition()
                }).catch(error => {
                  this.processingAjax = false
                  this.showMsgErrorRequest(error)
                })
              }
            } else {
              this.showMsgErrorRequest(new Error(this.$t('Preencha a data do seguimento')))
            }
          })
          .catch(() => {
            this.showMsgErrorRequest(new Error(this.$t('Preencha a data do seguimento')))
          })

        setTimeout(() => {
          self.clicked = false
        }, 1000)
      }
    },
    async saveFormEditionResp() {
      const self = this
      if ((typeof self.clicked === 'undefined') || (self.clicked === false)) {
        self.clicked = true
        await this.$refs.formSaveValidatorEdition.validate()
          .then(async valid => {
            if (valid) {
              this.processingAjax = true
              this.$store.dispatch('seguimentos/updateSeguimentoUserResp', {
                sw030s01: btoa(this.row.sw030s01),
                sw030s02: btoa(this.$refs.formEditionData.getData().sw030s02) || '',
              }).then(async res => {
                this.processingAjax = false

                this.row = { ...this.row, ...this.$refs.formEditionData.getData() }

                this.activeRefreshListview = true

                this.showMsgSuccessRequest(res)
                this.cancelFormEdition()
              }).catch(error => {
                this.processingAjax = false
                this.showMsgErrorRequest(error)
              })
            } else {
              this.showMsgErrorRequest(new Error(this.$t('Escolha um responsável')))
            }
          })
          .catch(() => {
            this.showMsgErrorRequest(new Error(this.$t('Escolha um responsável')))
          })

        setTimeout(() => {
          self.clicked = false
        }, 1000)
      }
    },
    async saveFormEditionChangeStatus() {
      const self = this
      if ((typeof self.clicked === 'undefined') || (self.clicked === false)) {
        self.clicked = true
        await this.$refs.formSaveValidatorEdition.validate()
          .then(async valid => {
            if (valid) {
              if (this.$refs.formEditionData.getData().dates.length > 0) {
                this.processingAjax = true
                this.$store.dispatch('seguimentos/updateRotinaUpdates', {
                  sw030s01: btoa(this.row.sw030s01),
                  dates: this.$refs.formEditionData.getData().dates,
                }).then(async res => {
                  this.processingAjax = false

                  this.activeRefreshListview = true

                  this.showMsgSuccessRequest(res)
                  this.cancelFormEdition()
                }).catch(error => {
                  this.processingAjax = false
                  this.showMsgErrorRequest(error)
                })
              } else {
                this.showMsgErrorRequest(new Error(this.$t('Não existem seguimentos para atualizar o estado')))
              }
            } else {
              this.showMsgErrorRequest(new Error(this.$t('Preencha o estado dos seguimentos')))
            }
          })
          .catch(() => {
            this.showMsgErrorRequest(new Error(this.$t('Preencha o estado dos seguimentos')))
          })

        setTimeout(() => {
          self.clicked = false
        }, 1000)
      }
    },
  },
  setup(props) {
    const row = ref(props.rowData)

    return {
      row,
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss" scoped>
.quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + .quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

::v-deep .ql-snow .ql-tooltip {
  white-space:normal!important;
}
</style>
